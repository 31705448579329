// @import './node_modules/bootstrap/scss/bootstrap.scss';

// @import "/../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css";

// @import 'components/card.scss';
// @import 'components/btn.scss';
// @import 'components/badge.scss';
// @import 'components/footer.scss';
// @import 'components/header.scss';
// @import 'components/layout.scss';
// @import 'components/seats.scss';
// @import 'components/slider.scss';
// @import 'components/result.scss';
// @import 'components/alert.scss';
// @import 'components/loading.scss';
// @import 'components/sac.scss';

body {
	display: block !important;
}

// teste